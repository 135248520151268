var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: { columns: _vm.columns, "data-source": _vm.invoices },
        scopedSlots: _vm._u([
          {
            key: "operations",
            fn: function(text, row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.showInvoice(row)
                        }
                      }
                    },
                    [_vm._v("\n        View\n      ")]
                  )
                ],
                1
              )
            }
          },
          {
            key: "created_at",
            fn: function(text) {
              return _c("div", {}, [
                _vm._v("\n      " + _vm._s(_vm.nFormat(text)) + "\n    ")
              ])
            }
          },
          {
            key: "total",
            fn: function(text) {
              return _c("div", {}, [
                _vm._v("€" + _vm._s(_vm.formatMoney(text)))
              ])
            }
          },
          {
            key: "transaction",
            fn: function(text, row) {
              return _c(
                "div",
                {},
                [
                  row.Transaction && row.Transaction.id
                    ? [
                        _c("a-tag", { attrs: { color: "green" } }, [
                          _vm._v("Payment Received")
                        ])
                      ]
                    : [
                        _c("a-tag", { attrs: { color: "orange" } }, [
                          _vm._v("Payment In Progress")
                        ])
                      ]
                ],
                2
              )
            }
          }
        ])
      }),
      _c(
        "a-modal",
        {
          attrs: { width: "400px" },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { xs: 12, lg: 12 } }, [
                _c("h4", [_vm._v(_vm._s(_vm.selectedInvoice.club_name))]),
                _c("p", [_vm._v(_vm._s(_vm.selectedInvoice.address))])
              ])
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { staticStyle: { "text-align": "left" }, attrs: { span: 24 } },
                [
                  _c("p", [
                    _c("strong", [_vm._v("Location:")]),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.selectedInvoice.location) + " ")
                    ])
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("Attendee:")]),
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.selectedInvoice.first_name) +
                          "\n            " +
                          _vm._s(_vm.selectedInvoice.last_name)
                      )
                    ])
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("Subtotal:")]),
                    _c("span", [
                      _vm._v(
                        " €" +
                          _vm._s(_vm.formatMoney(_vm.selectedInvoice.total))
                      )
                    ])
                  ]),
                  _vm.selectedInvoice.Transaction
                    ? _c("p", [
                        _c("strong", [_vm._v("Transaction ID:")]),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedInvoice.Transaction.transaction_id
                              )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("p", [
                    _c("strong", [_vm._v("Payment Card:")]),
                    _c("span", [_vm._v(" **** **** **** 4731")])
                  ]),
                  _vm.selectedInvoice.Transaction &&
                  _vm.selectedInvoice.Transaction.id
                    ? _c("p", [
                        _c("strong", [_vm._v("Payment Received:")]),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.nTime(
                                  _vm.selectedInvoice.Transaction.created_at
                                )
                              )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.selectedInvoice.Transaction &&
                  _vm.selectedInvoice.Transaction.id
                    ? [
                        _c(
                          "a-tag",
                          {
                            staticStyle: { "margin-right": "0" },
                            attrs: { color: "green" }
                          },
                          [_vm._v("PAID")]
                        )
                      ]
                    : [
                        _c(
                          "a-tag",
                          {
                            staticStyle: { "margin-right": "0" },
                            attrs: { color: "orange" }
                          },
                          [_vm._v("PENDING PAYMENT")]
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }