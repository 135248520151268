var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 8 } },
            [
              _c(
                "div",
                { staticClass: "add-new-card gx-text-right" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openNewCardModal }
                    },
                    [_vm._v("\n          Add new Card\n        ")]
                  )
                ],
                1
              ),
              _c(
                "a-card",
                {
                  staticClass: "gx-card-full",
                  attrs: { title: "Active Cards" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "credit-cards" },
                    _vm._l(_vm.cards, function(card, index) {
                      return _c("stripe-card-preview", {
                        key: index,
                        attrs: { card: card },
                        on: {
                          remove: _vm.removeCard,
                          setDefault: _vm.setDefault
                        }
                      })
                    }),
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 16 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-table-full gx-table-responsive",
                  attrs: { title: "My Billing History" }
                },
                [_c("member-billing-history-table")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("stripe-new-card-modal", {
        attrs: { visible: _vm.newCardModalVisible },
        on: { token: _vm.saveCard, close: _vm.closeNewCardModalVisible }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }